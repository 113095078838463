import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Launch from '@mui/icons-material/Launch';
import Download from '@mui/icons-material/Download';
import { gql, useMutation } from '@apollo/client';
import useDownloadPdfClientFile from '../../hooks/useDownloadPdfClientFile';
import { openUrlAndDownload } from '../../helpers';

const LegalDocumentChip = ({ document, companyId }) => {
  const [localDocument, setLocalDocument] = useState(document);
  const [generateNewdPdf] = useDownloadPdfClientFile(document.mutation);
  const [isGenerating, setIsGenerating] = useState(false);

  const [fetchDocument, { data, error, loading }] = useMutation(
    localDocument.mutation,
    {
      variables: { masterEntityId: companyId },
      notifyOnNetworkStatusChange: true },
  );
  const fetchColor = useMemo(() => {
    if (error) return 'warning';
    if (data) return 'success';
    if (['funds_back_up', 'client_file_uaf'].includes(localDocument.documentType)) return 'primary';
    if (localDocument.companyDocument) return 'primary';
    return 'default';
  }, [data, error, localDocument]);

  const fetchVariant = useMemo(() => {
    if (localDocument.companyDocument || data || error) return 'filled';
    return 'outlined';
  }, [localDocument]);

  const isLoading = useMemo(() => loading, [loading]);

  const openUrl = useCallback(async () => {
    setIsGenerating(true);
    if (!localDocument?.companyDocument) {
      const generatedFile = await generateNewdPdf(companyId);
      const possibleUrls = [
        generatedFile?.generateClientFilePdf?.url,
        generatedFile?.generateClientEvaluationPdf?.url,
      ];

      const newUrl = possibleUrls.find((url) => url);
      if (newUrl) {
        window.open(newUrl, '_blank');
        setLocalDocument((prev) => ({
          ...prev,
          companyDocument: {
            ...prev.companyDocument,
            lastFile: newUrl,
            lastDate: new Date().toISOString(),
          },
        }));
      } else {
        console.error('No se pudo obtener la URL presignada.');
      }
      setIsGenerating(false);
      return;
    }
    openUrlAndDownload(localDocument.companyDocument.lastFile);
    setIsGenerating(false);
  }, [localDocument]);
  return (
    <Tooltip title={localDocument.companyDocument ? `Obtención: ${localDocument.companyDocument.lastDate}` : ''}>
      <Chip
        size="small"
        label={localDocument.label}
        onClick={isGenerating ? undefined : openUrl}
        disabled={isGenerating}
        id={localDocument.documentType.toLowerCase()}
        color={fetchColor}
        variant={fetchVariant}
        icon={isGenerating ? <CircularProgress size={12} /> : <Launch />}
        onDelete={fetchDocument}
        deleteIcon={isLoading ? (
          <CircularProgress size={12} />
        ) : (
          <Download />
        )}
        sx={{ '& .MuiChip-label': { fontSize: 11 }, marginInline: 0.2, marginTop: 0.5 }}
      />
    </Tooltip>
  );
};

LegalDocumentChip.propTypes = {
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    documentType: PropTypes.string,
    companyDocument: PropTypes.shape({
      id: PropTypes.string.isRequired,
      globalAppId: PropTypes.string.isRequired,
      lastDate: PropTypes.string.isRequired,
      lastFile: PropTypes.string.isRequired,
    }),
    mutation: PropTypes.objectOf(gql).isRequired,
  }).isRequired,
};

export default LegalDocumentChip;
