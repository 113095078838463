import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Launch from '@mui/icons-material/Launch';
import Download from '@mui/icons-material/Download';
import { gql, useMutation } from '@apollo/client';
import { openUrlAndDownload } from '../../helpers';

const SatDocumentChip = ({ document, companyId }) => {
  const [fetchDocument, { data, error, loading }] = useMutation(
    document.mutation,
    {
      variables: { companyId, reportType: document.documentType },
      notifyOnNetworkStatusChange: true },
  );
  const fetchColor = useMemo(() => {
    if (error) return 'warning';
    if (data) return 'success';
    if (document.companyDocument) return 'primary';
    return 'default';
  }, [data, error, document]);

  const fetchVariant = useMemo(() => {
    if (document.companyDocument || data || error) return 'filled';
    return 'outlined';
  }, [document]);

  const isLoading = useMemo(() => loading, [loading]);
  const openUrl = useCallback(() => {
    if (!document) return;
    openUrlAndDownload(document.companyDocument.lastFile);
  }, [document]);
  return (
    <Tooltip title={document.companyDocument ? `Obtención: ${document.companyDocument.lastDate}` : ''}>
      <Chip
        size="small"
        label={document.label}
        onClick={openUrl}
        id={document.documentType.toLowerCase()}
        color={fetchColor}
        variant={fetchVariant}
        icon={<Launch />}
        onDelete={fetchDocument}
        deleteIcon={isLoading ? (
          <CircularProgress size={12} />
        ) : (
          <Download />
        )}
        sx={{ '& .MuiChip-label': { fontSize: 11 }, marginInline: 0.2, marginTop: 0.5 }}
      />
    </Tooltip>
  );
};

SatDocumentChip.propTypes = {
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    label: PropTypes.string,
    documentType: PropTypes.string,
    companyDocument: PropTypes.shape({
      id: PropTypes.string.isRequired,
      globalAppId: PropTypes.string.isRequired,
      lastDate: PropTypes.string.isRequired,
      lastFile: PropTypes.string.isRequired,
    }),
    mutation: PropTypes.objectOf(gql).isRequired,
  }).isRequired,
};

export default SatDocumentChip;
